<template>
  <div class="contain__">
    <div class="__input">
      <v-text-field
        solo
        dense
        id="src"
        type="search"
        autocomplete="off"
        label="City"
        hide-details
        v-model="find"
        @keyup="getCity"
      ></v-text-field>
    </div>
    <div class="__select__result">
      <v-card class="radius-card py-3 __card" width="100%">
        <div v-if="city">
          <section v-for="item in city.data" :key="item.id">
            <div class="d-flex pa-2 __item" @click="select(item)">
              <div>
                <h5 class="name__">{{ item.nama_kota }}</h5>
              </div>
            </div>
            <v-divider></v-divider>
          </section>
          <div v-if="!city.data.length" class="px-2">
            <p class="ma-0" v-if="!loading">"{{ find }}" tidak di temukan</p>
          </div>
        </div>
        <div class="mx-3 grey--text d-flex" v-else>
          <v-icon>mdi-magnify</v-icon>
          <h4>Type the name of the city or district</h4>
        </div>
        <div class="d-flex justify-center pa-2">
          <v-progress-circular
            :size="30"
            :width="5"
            color="blue"
            indeterminate
            v-if="loading"
          ></v-progress-circular>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "selectCity",
  computed: {
    ...mapState({
      city: state => state.area.generalCity,
      env: state => state.API_URL
    })
  },
  data() {
    return {
      find: "",
      page: 1,
      perPage: 7,
      loading: false
    };
  },

  mounted() {
    console.log(this.$route.name);
    let vm = this;
    document.addEventListener("click", function(event) {
      if (vm.$route.name == "listVacan") {
        let isClickInside = document
          .querySelector(".__select__result")
          .contains(event.target);
        let isClickInput = document
          .querySelector(".__input")
          .contains(event.target);

        if (!isClickInside && !isClickInput) {
          if (document.querySelector(".__select__result")) {
            document.querySelector(".__select__result").style.display = "none";
          }
        }
      }
    });
  },
  methods: {
    getCity() {
      if (this.find != "") {
        document.querySelector(".__select__result").style.display = "block";
      }
      let filter = {
        find: this.find,
        page: this.page,
        limit: this.perPage
      };
      this.loading = true;
      this.$store.dispatch("area/generalCity", filter).then(() => {
        this.loading = false;
      });
    },

    // view sikolog by username
    select(item) {
      this.$emit("get", item.id);
      console.log(item);
      this.find = item.nama_kota;
      document.querySelector(".__select__result").style.display = "none";
    }
  }
};
</script>

<style scoped>
.contain__ {
  position: relative;
}
.__select__result {
  display: none;
  position: absolute;
  width: 100%;
  margin-top: 10px;
  z-index: 3;
}
.__card {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
.name__ {
  color: #424242;
}
.__item:hover {
  background: #eeeeee;
  cursor: pointer;
}
</style>
