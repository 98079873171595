<template>
  <div class="_bg-default _80w _100vh pa-3">
    <v-card class="radius-card pa-3 _100top mb-3">
      <v-toolbar dense flat v-if="role == 1 || role == 3">
        <v-btn
          outlined
          to="/admin/create-vacancies"
          color="green"
          class="mr-3"
          rounded
          ><v-icon>mdi-plus</v-icon><b>Create Vacancies</b></v-btn
        >
      </v-toolbar>
    </v-card>
    <v-card class="radius-card pa-3">
      <v-row>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12" md="1">
              <v-icon>mdi-magnify</v-icon>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                label="Job title, keyword or company"
                v-model="byTitle"
                @keydown="getVacancies"
                required
                dense
                hide-details
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row>
            <v-col cols="12" md="1">
              <v-icon>mdi-google-maps</v-icon>
            </v-col>
            <v-col cols="12" md="10">
              <SelectCity @get="getCity" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12" md="1">
              <v-icon>mdi-briefcase</v-icon>
            </v-col>
            <v-col cols="12" md="11">
              <v-select
                v-if="category"
                solo
                :items="category.data"
                item-text="nama"
                item-value="nama"
                v-model="byCategory"
                label="position"
                hide-details
                @input="getVacancies"
                dense
                class="white"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn dark color="red" @click="resetSearch">Reset</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- <div class="py-2 d-flex justify-end">
    </div> -->
    <v-card class="mt-3 pa-3 radius-card" v-if="vacancies">
      <v-row class="mt-3">
        <v-col cols="12" md="6" v-for="item in vacancies" :key="item.id">
          <v-card
            class="pa-3 radius-card"
            height="200"
            :to="`/vacancies/${item.id}`"
          >
            <div class="d-flex mb-2">
              <img
                :src="`${env}/vacancies/${item.logo}`"
                height="50px"
                alt="log-company"
              />
              <section class="mb-1 ml-2">
                <h3 class="blue--text min__">{{ item.judul }}</h3>
                <small>{{ item.perusahaan }}</small>
              </section>
            </div>
            <small v-snip="3" v-html="item.deskripsi"></small>
          </v-card>
        </v-col>
      </v-row>
      <section class="d-flex justify-center pa-3" v-if="!vacancies.length">
        <div><img src="../../assets/img/404.svg" alt="404" />No data Found</div>
      </section>
    </v-card>
    <div class="d-flex justify-ceneter" v-if="loading">loading</div>
    <div v-if="vacancies" v-observe-visibility="handleScroll"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SelectCity from "../../components/_base/selectCityVacan.vue";

export default {
  name: "myVancancies",
  components: {
    SelectCity
  },
  computed: {
    ...mapState({
      city: state => state.area.generalCity,
      category: state => state.vacancies.category,
      vacancies: state => state.vacancies.vacancies,
      last_page_vacancies: state => state.vacancies.last_page_vacancies,
      role: state => state.role,
      env: state => state.API_URL,
      id: state => state.id
    })
  },

  data() {
    return {
      find: "",
      searchCity: "",
      searchCategory: "",
      byCity: "",
      byTitle: "",
      byCategory: "",
      page: 1,
      perpage: 10,
      loadVacan: false,
      loading: false
    };
  },
  mounted() {
    this.getCity();
    this.getVacancies();
    this.getTitle();
  },

  methods: {
    getCity(id) {
      if (id) {
        this.byCity = id;
        this.getVacancies();
      }
    },
    getVacancies() {
      let filter = {
        title: this.byTitle,
        city: this.byCity,
        category: this.byCategory,
        id: this.id,
        page: this.page,
        per_page: this.perPage
      };
      this.$store.dispatch("vacancies/myVacancies", filter);
    },

    resetSearch() {
      this.byCity = "";
      this.byTitle = "";
      this.byCategory = "";
      this.getVacancies();
    },

    get_group(id) {
      console.log(id);
    },

    handler(search, loading) {
      console.log(search);
      console.log(loading);
    },

    getTitle() {
      this.$store.dispatch("vacancies/category");
    },

    handleScroll(isVisible) {
      if (isVisible) {
        if (this.page <= this.last_page_vacancies) {
          this.page++;
          this.getVacancies();
        }
      }
    }
  }
};
</script>

<style scoped>
.min__ {
  line-height: 1;
}
</style>
